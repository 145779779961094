import { FormLayout, Modal } from "@shopify/polaris";
import { NO_OP_CALLBACK } from "@smartrr/shared/constants";
import { ISmartrrShopTheme } from "@smartrr/shared/shopifyRest/theme";
import { toViewShortDate } from "@smartrr/shared/utils/renderViewDate";
import { isUndefined } from "lodash";
import React, { useMemo } from "react";
import styled from "styled-components";
import * as yup from "yup";

import { useTypedForm } from "@vendor-app/app/_sharedComponents/TypedForm/useTypedForm";

interface Props {
  open: boolean;
  themes: ISmartrrShopTheme[];
  onClose(): void;
  onSubmit(theme: ISmartrrShopTheme): void;
  lastSavedThemeId?: string;
}

const DropdownWrapper = styled.div`
  .Polaris-Select__SelectedOption::after {
    color: red;
  }
`;

export function ApplyToThemeModal({ open, themes, onSubmit, onClose, lastSavedThemeId }: Props): JSX.Element {
  const sortedThemes = useMemo(
    () =>
      [...themes]
        .sort((a, b) => {
          if (
            isUndefined(lastSavedThemeId) ? a.role === "main" : a.id === (lastSavedThemeId && +lastSavedThemeId)
          ) {
            return -1;
          } else if (+new Date(a.updated_at) > +new Date(b.updated_at)) {
            return -1;
          }
          return 1;
        })
        .map(theme => ({
          value: theme.id,
          label: `${theme.name} | Updated: ${toViewShortDate(new Date(theme.updated_at))} ${
            theme.role === "main" ? "(Live theme)" : ""
          }`,
        })),
    [themes]
  );
  const { useField, useValues } = useTypedForm<{ themeId: number }>({
    initialValues: { themeId: sortedThemes[0]?.value || 0 },
    validationSchema: yup.object().shape<{ themeId: number }>({ themeId: yup.number() }),
    onSubmit: NO_OP_CALLBACK,
  });
  const { Dropdown } = useField("themeId");
  const values = useValues();

  const onApplyCSSToTheme = async () => {
    await onSubmit(themes.find(theme => theme.id === values.themeId)! || sortedThemes[0].value);
  };

  const secondaryActions = useMemo(
    () => [
      {
        content: "Cancel",
        onAction: onClose,
      },
    ],
    [onClose]
  );
  const primaryAction = useMemo(
    () => ({
      content: "Confirm",
      onAction: () => onApplyCSSToTheme(),
    }),
    [onApplyCSSToTheme]
  );

  return (
    <Modal
      title="Theme Confirmation"
      open={open}
      onClose={onClose}
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <DropdownWrapper>
              <Dropdown usePolaris description="themes" options={sortedThemes} />
            </DropdownWrapper>
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
